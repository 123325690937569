import React from 'react';

import PrimaryNavMenuClose from '@/components/layouts/header/primary-nav-menu-close';
import { Icon } from '@/components/shared/element/icons';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Grid } from '@/components/shared/layout/grid';
import { styled } from '@/stitches.config';

const PrimaryNavTopWithBackMenuStyle = styled(Box, {
  height: 'calc(var(--primary-nav-height) + 1px)',
  display: 'grid',
  gridTemplateColumns: 'auto max-content',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '$space-6',
});

interface PrimaryNavTopWithBackMenuProps {
  label: string;
  onClickBackMenu?: () => void;
  onClickCloseMenu?: () => void;
  isHideDesktop?: boolean;
}

const PrimaryNavTopWithBackMenu: React.FC<PrimaryNavTopWithBackMenuProps> = ({
  label,
  onClickBackMenu,
  onClickCloseMenu,
  isHideDesktop,
}: PrimaryNavTopWithBackMenuProps) => {
  return (
    <PrimaryNavTopWithBackMenuStyle
      position="relative"
      width="full"
      paddingX={{ '@initial': '6', '@sm': '12' }}
      cursor="pointer"
      border="b-1"
      borderColor="gray300"
      display={isHideDesktop && { '@lg': 'none' }}
      onClick={onClickBackMenu}
      test={{
        dataTest: 'navigation_menu_back',
      }}
    >
      <Grid
        alignItems="center"
        columnGap="1"
        css={{ height: '100%', gridTemplateColumns: 'max-content auto' }}
      >
        <Icon
          icon="chevron-left"
          color="icon-red"
          css={{ marginLeft: '-$space-2' }}
        />
        <Text size="text-body-1" font="bold" variant="text-red">
          {label}
        </Text>
      </Grid>
      <PrimaryNavMenuClose
        onClickCloseMenu={onClickCloseMenu}
        tracking={{
          dataTrack: 'primary-nav',
          dataTrackText: 'Menu Close Button',
        }}
        test={{
          dataTest: 'menu_close_button',
        }}
      />
    </PrimaryNavTopWithBackMenuStyle>
  );
};

PrimaryNavTopWithBackMenu.displayName = 'PrimaryNavTopWithBackMenu';

export default PrimaryNavTopWithBackMenu;
