import React, { ComponentType, useMemo } from 'react';

import { Icon } from '@/components/shared/element/icons';
import { Link } from '@/components/shared/element/link';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Grid } from '@/components/shared/layout/grid';
import { css } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

export const PrimaryNavMenuLinkStyle = css({
  position: 'relative',
  minHeight: 'var(--primary-nav-height)',
  paddingLeft: '$space-6',
  paddingRight: '$space-6',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '$space-4',
  cursor: 'pointer',
  transform: 'translateZ(0px)',
  transitionProperty: 'transform',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: '0.6s',
  transitionTimingFunction: 'var(--transition-easing)',
  willChange: 'transform',
  '@lg': {
    overflow: 'hidden',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '$space-0',
      left: '$space-0',
      backgroundColor: '$primary',
      zIndex: '$1',
      pointerEvents: 'none',
      transitionProperty: 'transform',
      transitionDelay: 'var(--transition-delay)',
      transitionDuration: 'var(--transition-duration)',
      transitionTimingFunction: 'var(--transition-easing)',
    },
    '&.is-menu-open': {
      '&::before': {
        transform: 'translateY(0)',
      },
      '& i': {
        transform: 'rotate(180deg)',
      },
    },
  },
  variants: {
    isTransparentNavigation: {
      true: {
        '@lg': {
          '&::before': {
            content: 'none',
          },
          '&.is-submenu-open': {
            span: {
              color: '$current',
              textShadow: 'none',
              '&::after': {
                content: 'attr(data-title)',
                opacity: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                backgroundColor: '$white',
                br: '$rounded-1',
                transform: 'translate(-50%, -50%)',
                width: 'calc(100% + $size-8)',
                height: 'calc(100% + $size-3)',
                zIndex: '$2',
                fontFamily: '$bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                transition: 'background-color 0.6s ease',
              },
            },
          },
          span: {
            position: 'relative',
            zIndex: '$1',
            color: '$white',
            textShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          },
          '& i': {
            display: 'none',
          },
          '&.is-menu-open': {
            overflow: 'initial',
            span: {
              color: '$gray1000',
              zIndex: '$1',
              '&::after': {
                opacity: 1,
                backgroundColor: '$gray300',
              },
            },
          },
        },
      },
    },
    variant: {
      'main-menu': {
        paddingTop: '$space-3',
        paddingBottom: '$space-3',
        '@sm': {
          paddingLeft: '$space-12',
          paddingRight: '$space-12',
        },
        '@lg': {
          paddingTop: '$space-0',
          paddingBottom: '$space-0',
          paddingLeft: '$space-0',
          paddingRight: '$space-0',
          display: 'inline-flex',
          columnGap: '$space-1',
          '&::before': {
            width: '100%',
            height: '$size-1',
            transform: 'translateY(100%)',
          },
          '@hover': {
            '&:hover': {
              '&::before': {
                transform: 'translateY(0)',
              },
            },
          },
        },
      },
      submenu: {
        paddingTop: '$space-3',
        paddingBottom: '$space-3',
        backgroundColor: '$gray200',
        '@sm': {
          paddingLeft: '$space-12',
          paddingRight: '$space-12',
        },
        '@lg': {
          backgroundColor: '$transparent',
          minHeight: 'auto',
          paddingLeft: '$space-0',
          paddingRight: '$space-0',
          paddingTop: '$space-0',
          paddingBottom: '$space-0',
          display: 'flex',
          justifyContent: 'flex-start',
          transitionProperty: 'transform, padding-left',
          '&::before': {
            width: '$size-1',
            height: '100%',
            transform: 'translateX(-100%)',
          },
          '@hover': {
            '&:hover': {
              paddingLeft: '$space-4',
              '&::before': {
                transform: 'translateX(0)',
              },
            },
          },
          '&.is-current': {
            paddingLeft: '$space-4',
            '&::before': {
              transform: 'translateX(0)',
            },
          },
        },
      },
      'submenu-level-2': {
        paddingTop: '$space-3',
        paddingBottom: '$space-3',
        backgroundColor: '$gray200',
        // minHeight: '$size-12',
        '@sm': {
          paddingLeft: '$space-12',
          paddingRight: '$space-12',
        },
        '@lg': {
          backgroundColor: '$transparent',
          minHeight: 'auto',
          paddingLeft: '$space-0',
          paddingRight: '$space-0',
          paddingTop: '$space-0',
          paddingBottom: '$space-0',
          display: 'flex',
          justifyContent: 'flex-start',
          transitionProperty: 'color',
          transitionDelay: 'var(--transition-delay)',
          transitionDuration: 'var(--transition-duration)',
          transitionTimingFunction: 'var(--transition-easing)',
          '&::before': {
            content: 'none',
          },
          '@hover': {
            '&:hover': {
              '& *': {
                color: '$primary',
                '& span': {
                  color: 'inherit !important',
                },
              },
            },
          },
        },
      },
      panel: {
        paddingTop: '$space-3',
        paddingBottom: '$space-3',
        '@lg': {
          '&::before': {
            height: '100%',
            width: '$size-1',
            transform: 'translateX(-100%)',
          },
          '@hover': {
            '&:hover': {
              '&::before': {
                transform: 'translateX(0)',
              },
            },
          },
        },
      },
      model: {
        backgroundColor: '$gray300',
        '@sm': {
          paddingLeft: '$space-12',
          paddingRight: '$space-12',
        },
      },
    },
    isSubmenu: {
      true: {},
    },
    isCurrent: {
      true: {},
    },
  },
  compoundVariants: [
    {
      variant: 'submenu',
      isSubmenu: 'true',
      css: {
        '@maxlg': {
          backgroundColor: '$gray300',
        },
      },
    },
    {
      variant: 'submenu',
      isCurrent: 'true',
      css: {
        '& i': {
          '@maxlg': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'main-menu',
  },
});

interface PrimaryNavMenuLinkProps
  extends IMenu,
    React.ComponentProps<typeof PrimaryNavMenuLinkStyle> {
  onClickSubmenu?: () => void;
  isToggleMenu?: boolean;
  index?: number;
  submenuId?: number;
  variant?: 'main-menu' | 'submenu' | 'submenu-level-2' | 'panel' | 'model';
  onMouseOver?: () => void;
  className?: string;
  IconCar?: ComponentType;
  isSubmenu?: boolean;
  isCurrent?: boolean;
  isTransparentNavigation?: boolean;
}

const PrimaryNavMenuLink: React.FC<PrimaryNavMenuLinkProps> = ({
  index = 0,
  submenuId = -1,
  isToggleMenu = false,
  onClickSubmenu,
  onMouseOver,
  variant,
  className,
  IconCar,
  isSubmenu,
  isCurrent,
  isTransparentNavigation,
  ...rest
}: PrimaryNavMenuLinkProps) => {
  const commonProps = {
    tracking: {
      dataTrack: 'primary-nav',
      dataTrackText: rest.label,
      dataTrackUrl: rest.url,
    },
    test: {
      dataTest: 'navigation_menu_link',
    },
  };

  const isMenuOpenClass = useMemo(
    () => (submenuId === index ? ' is-menu-open' : ''),
    [submenuId, index]
  );
  const isSubmenuOpen = useMemo(
    () => (submenuId !== -1 ? ' is-submenu-open' : ''),
    [submenuId]
  );

  return (
    <>
      {rest.url ? (
        <Link
          href={rest.url}
          target={rest.target}
          {...commonProps}
          onMouseOver={onMouseOver}
          className={`${PrimaryNavMenuLinkStyle({
            isTransparentNavigation: isTransparentNavigation,
            variant: variant,
          })}${isMenuOpenClass}${isSubmenuOpen}${
            className ? ` ${className}` : ''
          }`}
        >
          {variant === 'model' ? (
            <Grid
              alignItems="center"
              columnGap="4"
              css={{ gridTemplateColumns: 'max-content auto' }}
            >
              {IconCar && <IconCar />}
              <Text size="text-body-1">{rest.label}</Text>
            </Grid>
          ) : (
            <Text
              size={variant === 'submenu-level-2' ? 'text-base' : 'text-body-1'}
              wrap
              dangerouslySetInnerHTML={{ __html: rest.label }}
            />
          )}
          {rest.target === '_blank' && (
            <Icon
              icon="open-in-new"
              css={variant === 'main-menu' && { '@lg': { display: 'none' } }}
            />
          )}
        </Link>
      ) : (
        <Box
          className={`${PrimaryNavMenuLinkStyle({
            isTransparentNavigation: isTransparentNavigation,
            variant: variant,
            isSubmenu: isSubmenu,
            isCurrent: isCurrent,
          })}${isMenuOpenClass}${isSubmenuOpen}${
            className ? ` ${className}` : ''
          }`}
          onClick={onClickSubmenu}
          onMouseOver={onMouseOver}
          {...commonProps}
        >
          <Text
            size="text-body-1"
            font={isSubmenu && { '@maxlg': 'bold' }}
            wrap
            dangerouslySetInnerHTML={{ __html: rest.label }}
            data-title={rest.label}
          />
          {rest.submenu && rest.submenu.length > 0 && (
            <Icon
              icon={isToggleMenu ? 'chevron-right' : 'expand-more'}
              css={variant === 'submenu' && { '@lg': { display: 'none' } }}
            />
          )}
        </Box>
      )}
    </>
  );
};

PrimaryNavMenuLink.displayName = 'PrimaryNavMenuLink';

export default PrimaryNavMenuLink;
