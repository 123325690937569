import { useEffect, useRef, useState, useTransition } from 'react';

import { disposables } from '@/components/hooks/useMountTransition';

export const useCustomMountTransition = (
  currentState: any,
  duration: number
) => {
  const [isPendingTransition, startTransition] = useTransition();
  const [currentStateNextFrame, setCurrentStateNextFrame] = useState();
  const [transitionState, setTransitionState] = useState<any>();
  const d = useRef(disposables());

  useEffect(() => {
    if (isPendingTransition) return;
    d.current.nextFrame(() => {
      setCurrentStateNextFrame(currentState);
    });

    d.current.setTimeout(() => {
      setTransitionState(currentState);
    }, duration);

    return d.current.dispose;
  }, [isPendingTransition]);

  return {
    currentStateNextFrame,
    transitionState,
    startTransition,
  };
};
